import React from 'react';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { TAfindInstaId, TAfindTaggedPosts, TAfindUsername } from '../services/userAPI';
import { setPageTitle } from '../redux/store/themeConfigSlice';

const UserTaggedPostsScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageTitle('Etiketlenen Gönderileri Bul'));
  }, [dispatch]);

  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState('');
  const [username, setUsername] = useState('');
  const [postsData, setPostsData] = useState([]);
  const [error, setError] = useState<string | null>(null);
  const [autofillUsernames, setAutofillUsernames] = useState<string[]>([]);
  const [showFullText, setShowFullText] = useState<{ [key: number]: boolean }>({});

  const handleForm = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (userId) {
        await getTaggedPosts(userId);
      } else if (!username && !userId) {
        setError('Please enter a username or user id');
        setIsLoading(false);
      } else {
        const user_id = await TAfindInstaId(username);
        await getTaggedPosts(user_id);
      }
    } catch (error: any) {
      if (error.response) {
        setError(error.response.error);
      } else {
        setError(error.message);
      }
      setIsLoading(false);
    }
  };

  const getTaggedPosts = async (user_id: any) => {
    try {
      const response = await TAfindTaggedPosts(user_id);
      const newPostsData = response.map((res: any) => {
        if (res.node === undefined) {
          return;
        }
        const post = res.node;
        return {
          id: post.id,
          __typename: post.__typename,
          text: post.edge_media_to_caption?.edges[0]?.node?.text || '',
          owner_id: post.owner.id,
          owner_username: post.owner.username,
          edge_liked_by: post.edge_liked_by.count,
          edge_media_to_comment: post.edge_media_to_comment.count,
          video_view_count: post.video_view_count || 0,
          display_url: post.display_url,
          thumbnail_src: post.thumbnail_src,
          taken_at_timestamp: post.taken_at_timestamp,
        };
      });
      setPostsData(newPostsData);
      setIsLoading(false);
    } catch (error: any) {
      if (error.response) {
        setError(error.response.error);
      } else {
        setError(error.message);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const autofillUsername = async () => {
      try {
        const response = await TAfindUsername(username);
        setAutofillUsernames(response);
      } catch (error) {
        throw error;
      }
    };
    if (username.length > 1) {
      autofillUsername();
    }
  }, [username]);

  const handleSuggestionClick = (selectedUsername: any) => {
    setUsername(selectedUsername);
    setAutofillUsernames([]);
  };

  const toggleTextVisibility = (index: number) => {
    setShowFullText((prevShowFullText) => ({
      ...prevShowFullText,
      [index]: !prevShowFullText[index],
    }));
  };

  const truncateText = (text: string) => {
    if (text.length <= 500) {
      return text;
    }
    return `${text.substring(0, 500)}... `;
  };

  return (
    <div className="flex flex-col lg:flex-row justify-between items-start min-h-screen bg-cover bg-center relative">
      <form className="w-1/4 absolute right-6 " onSubmit={handleForm}>
        <h1>Find User Tagged Posts</h1>

        <h2 className="text-sm font-bold mb-1 mt-3 ml-2">IG Username</h2>
        <input
          type="text"
          placeholder="IG Username"
          className="form-input text-sm"
          value={username}
          onChange={(e) => {
            setUsername(e.target.value);
          }}
        />

        {autofillUsernames.length > 0 && username !== '' && (
          <ul className="suggestion-list" style={{ position: 'absolute', zIndex: 9999 }}>
            {[...new Set(autofillUsernames)].slice(0, 5).map((autofillUsername, index) => (
              <li
                key={index}
                className="bg-white p-2 text-black cursor-pointer hover:bg-gray-200"
                onClick={() => handleSuggestionClick(autofillUsername)}
              >
                {autofillUsername}
              </li>
            ))}
          </ul>
        )}

        <h2 className="text-sm font-bold mb-1 mt-3 ml-2">User ID:</h2>
        <input
          type="text"
          placeholder="User ID"
          className="form-input text-sm"
          value={userId}
          onChange={(e) => {
            setUserId(e.target.value);
          }}
        />
        {error && <p className="text-red-500 text-sm text-center">{error}</p>}
        <div className="flex justify-center">
          <button type="submit" className="btn btn-primary mt-3">
            Submit
          </button>
        </div>
      </form>
      {isLoading && (
        <div className="flex items-center justify-center h-40">
          <div className="animate-spin rounded-full h-10 w-10 border-t-4 border-pink-600"></div>
        </div>
      )}
      {postsData.length > 0 && (
        <div className="w-full mt-10 lg:mt-0 lg:w-3/4 p-6">
          <h2 className="text-2xl font-bold mb-6">Tagged Posts</h2>
          <table className="min-w-full bg-white mt-44">
            <thead>
              <tr>
                <th className="py-2">Post ID</th>
                <th className="py-2">Text</th>
                <th className="py-2">Owner Username</th>
                <th className="py-2">Likes</th>
                <th className="py-2">Comments</th>
                <th className="py-2">Views</th>
                <th className="py-2">Date</th>
                <th className="py-2">Display Url</th>
              </tr>
            </thead>
            <tbody>
              {postsData.map((post: any, index: number) => (
                <tr key={post.id}>
                  <td className="border px-4 py-2">{post.id}</td>
                  <td className="border px-4 py-2">
                    {showFullText[index] || post.text.length <= 100 ? post.text : `${post.text.substring(0, 100)}...`}
                    {post.text.length > 100 && (
                      <button onClick={() => toggleTextVisibility(index)} className="text-blue-500 ml-2">
                        {showFullText[index] ? 'Kısalt' : 'Devamını Oku'}
                      </button>
                    )}
                  </td>
                  <td className="border px-4 py-2">{post.owner_username}</td>
                  <td className="border px-4 py-2">{post.edge_liked_by}</td>
                  <td className="border px-4 py-2">{post.edge_media_to_comment}</td>
                  <td className="border px-4 py-2">{post.video_view_count}</td>
                  <td className="border px-4 py-2">{new Date(post.taken_at_timestamp * 1000).toLocaleDateString()}</td>
                  <td className="border px-4 py-2">{post.display_url}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default UserTaggedPostsScreen;
