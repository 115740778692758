import apiClient from './axiosInstance';

export const TAstatistics = async () => {
  try {
    const response = await apiClient.get(`/admin/statistics/statisticsnum`, {
      timeout: 60000,
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Statistics not found');
    }
  } catch (error) {
    throw error;
  }
};

export const TAtcNoControl = async (tcNo: string, firstName: string, lastName: string, birthYear: string) => {
  try {
    const response = await apiClient.post(
      `/admin/statistics/tcnocontrol?tcNo=${tcNo}&firstName=${firstName}&lastName=${lastName}&birthYear=${birthYear}`,
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Statistics not found');
    }
  } catch (error) {
    throw error;
  }
};

export const TargetPopularBrands = async (perPage: any, page: number) => {
  try {
    const response = await apiClient.get(`/admin/brand/getpopularbrands?perPage=${perPage}&page=${page}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Update failed');
    }
  } catch (error) {
    throw error;
  }
};
