import React, { useState } from 'react';
import { TAupdateInsta } from '../services/userAPI';

export const UpdateInsta = ({ _id, username, onClose }: { _id: string; username: string; onClose: () => void }) => {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [insta, setInsta] = useState('');
  const [notification, setNotification] = useState('');

  const handleUpdateInstagram = async (insta_username: string, _id: string) => {
    setIsLoading(true);
    try {
      const res = await TAupdateInsta(insta_username, _id);
      if (res) {
        setIsLoading(false);
        setNotification('Instagram updated');
        onClose();
      }
    } catch (error) {
      setIsLoading(false);
      setError(`Insta update failed, try again ${error}`);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center" style={{ zIndex: 9999 }}>
      <div className="bg-white p-8 rounded-lg shadow-lg overflow-y-auto h-3/7 max-w-md w-full space-y-4">
        {notification && (
          <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-3">
            <span className="flex items-center block sm:inline">{notification}</span>
          </div>
        )}
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-3">
            <span className="flex items-center block sm:inline">{error}</span>
          </div>
        )}
        <h2 className="text-xl font-semibold mb-4">Update Insta</h2>
        <form className="space-y-4">
          <label className="block">
            Recent Insta username : <strong>{username || 'not found'}</strong>
          </label>
          <label className="block">Insta username</label>
          <input
            type="text"
            value={insta}
            onChange={(e) => setInsta(e.target.value)}
            className="w-full border border-gray-300 rounded px-4 py-2"
          />
          <div className="flex justify-end space-x-4">
            <button type="button" className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded" onClick={onClose}>
              Cancel
            </button>
            <button
              type="button"
              onClick={() => handleUpdateInstagram(insta, _id)}
              className="px-4 py-2 bg-blue-500 text-white hover:bg-blue-600 rounded"
              disabled={isLoading}
            >
              {isLoading ? 'Loading...' : 'Save'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
