import apiClient from './axiosInstance';

export const TAfindUser = async (query: any) => {
  try {
    const response = await apiClient.get(`/admin/user/getuser?${query}`, {});

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Find User failed');
    }
  } catch (error) {
    throw error;
  }
};

export const TAfindUsername = async (data: any) => {
  try {
    const response = await apiClient.get(`/admin/user/getusername?username=${data}`, {});

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Find User failed');
    }
  } catch (error) {
    throw error;
  }
};

export const TAfindCountry = async (data: any) => {
  try {
    const response = await apiClient.get(`/admin/user/getcountry?country=${data}`, {});

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Find User failed');
    }
  } catch (error) {
    throw error;
  }
};

export const TAfindCity = async (data: any) => {
  try {
    const response = await apiClient.get(`/admin/user/getcity?city=${data}`, {});

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Find User failed');
    }
  } catch (error) {
    throw error;
  }
};

export const TAfindJob = async (data: any) => {
  try {
    const response = await apiClient.get(`/admin/user/getjob?job=${data}`, {});

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Find User failed');
    }
  } catch (error) {
    throw error;
  }
};

export const TAfindHobbies = async (data: any) => {
  try {
    const response = await apiClient.get(`/admin/user/gethobbies?hobbies=${data}`, {});

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Find User failed');
    }
  } catch (error) {
    throw error;
  }
};

export const TAfindAllUser = async (page: number, perPage: number, params: any, sortBy: string, sortOrder: boolean) => {
  try {
    const response = await apiClient.get(
      `/admin/user/getall?${params}&page=${page}&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
      {},
    );
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Find User failed');
    }
  } catch (error) {
    throw error;
  }
};

export const TAfindApprovalUser = async (params: any) => {
  try {
    const response = await apiClient.get(`/admin/user/getverificationprofiles?${params}`, {});

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Find Approval Users failed');
    }
  } catch (error) {
    throw error;
  }
};

export const TAfindAllApprovalUser = async (
  page: number,
  perPage: number,
  params: any,
  sortBy: string,
  sortOrder: boolean,
) => {
  try {
    const response = await apiClient.get(
      `/admin/user/getallverificationprofiles?${params}&page=${page}&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
      {},
    );
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Find User failed');
    }
  } catch (error) {
    throw error;
  }
};

export const TAverifyUser = async (id: string, status: string) => {
  try {
    const response = await apiClient.post(
      `/admin/user/verificateuser/${id}/${status}`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const TAapprovedUser = async (
  params: any,
  page: number,
  perPage: number,
  sortBy: string,
  sortOrder: boolean,
) => {
  try {
    const response = await apiClient.get(
      `/admin/user/getverifiedprofiles?${params}&page=${page}&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
      {},
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Find Approved User failed');
    }
  } catch (error) {
    throw error;
  }
};

export const TAfindUserById = async (id: any) => {
  try {
    const response = await apiClient.get(`/admin/user/getuser/${id}`, {});

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Find User failed');
    }
  } catch (error) {
    throw error;
  }
};

export const TArecoverAccount = async (id: string, status: string) => {
  try {
    const response = await apiClient.post(
      `/admin/user/recoveraccount/${id}/${status}`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const TAchangePhone = async (id: string, phone: string) => {
  try {
    const response = await apiClient.post(
      `/admin/user/changephone/${id}/${phone}`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const TAremoveVerification = async (id: string) => {
  try {
    const response = await apiClient.put(`/admin/user/removeverification/${id}`, {});

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Find Remove Verification failed');
    }
  } catch (error) {
    throw error;
  }
};

export const TAfindInstaId = async (username: string) => {
  try {
    const response = await apiClient.post(`/admin/user/getinstaid/`, { username }, {});

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Find User Tagged Posts failed');
    }
  } catch (error) {
    throw error;
  }
};

export const TAfindTaggedPosts = async (user_id: string) => {
  try {
    const response = await apiClient.post(`/admin/user/getusertags/`, { user_id }, {});

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Find User Tagged Posts failed');
    }
  } catch (error) {
    throw error;
  }
};

export const TAupdateInsta = async (username: string, _id: string) => {
  try {
    const response = await apiClient.post(`/admin/user/updateinsta`, {
      username,
      _id,
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Find User failed');
    }
  } catch (error) {
    throw error;
  }
};

export const TAupdateTiktok = async (username: string, _id: string) => {
  try {
    const response = await apiClient.post(`/admin/user/updatetiktok`, {
      username,
      _id,
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Find User failed');
    }
  } catch (error) {
    throw error;
  }
};
