import apiClient from './axiosInstance';

export const TASearchByUsername = async (username: string, type: string) => {
  try {
    const response = await apiClient.get(`/admin/user/search-by-username?username=${username}&type=${type}`, {
      timeout: 10000,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      console.log('response', response);
      throw new Error('Username search failed');
    }
  } catch (error) {
    console.log('error: ', error);
    throw error;
  }
};

export const TAfindApplicationByCampaignId = async (campaign_id: any, state: string) => {
  try {
    const response = await apiClient.get(`/admin/application/campaign_id/${campaign_id}?state=${state}`);
    if (response.status === 200) {
      return response.data;
    } else {
      console.log('response', response);
      throw new Error('Find User failed');
    }
  } catch (error) {
    console.log('error: ', error);
    throw error;
  }
};

export const TACreateApplication = async (
  applicationUser: {
    user_id: string;
    insta_username: string;
    age: number;
    gender: string;
    school_type: string;
    // school_name: string,
    city: string;
    country: string;
    language: string;
    currency: string;
    job: string;
    birthday: string;
    verification: boolean;
    hobbies: string[];
  },
  campaign_id: string,

  selectedState: string,
  useCredentials: any,
  campaign_type: string,
) => {
  try {
    const response = await apiClient.post(
      `/admin/application/create`,
      {
        user: applicationUser, // Make sure this matches ApplicationUserInterface
        campaign_id: campaign_id, // The actual campaign ID
        state: selectedState,
        use_credentials: useCredentials,
        campaign_type: campaign_type,
      },
      {
        timeout: 120000,
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    console.log('response request : ' + response);
    if (response.status === 200) {
      return response.data;
    } else {
      console.log('response', response);
      throw new Error('Creating application failed');
    }
  } catch (error) {
    console.log('error: ', error);
    throw error;
  }
};

export const TAupdateApplication = async (update: any, _id: string) => {
  try {
    const response = await apiClient.post(
      `/admin/application/update`,
      {
        _id: _id,
        update: update,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    console.log(response);
    if (response.status === 200) {
      return response.data;
    } else {
      console.log('response', response);
      throw new Error('Updating application pricing failed');
    }
  } catch (error) {
    console.log('error: ', error);
    throw error;
  }
};

export const TAuploadUserPayload = async (id: any, data: any, type: string) => {
  try {
    const response = await apiClient.post(`/admin/application/upload/${id}?type=${type}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent></calculated>',
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const TAassignUserToApplication = async (id: any, data: any) => {
  try {
    const response = await apiClient.post(
      `/admin/application/assign-user/${id}`,
      { user_id: data },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    return response;
  } catch (error) {
    throw error;
  }
};
