import React from 'react';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  TASpamBrand,
  TAbalanceLoaded,
  TAcampaignCompleted,
  TAcampaignManagementContinues,
  TAdemoCampaignCreated,
  TAdirectedToAgencyBasedWork,
  TAfindAllBrands,
  TAinformationMailSent,
  TAmeetingPlanned,
  TApersonalAccount,
  TAupdateBrandMeeting,
  TAupdateBrandNote,
  TAupdateBrandStage,
} from '../services/brandAPI';
import { DataTable, DataTableSortStatus } from 'mantine-datatable';
import sortBy from 'lodash/sortBy';
import { setPageTitle } from '../redux/store/themeConfigSlice';
import { AllBrandType, Meetings, Stages } from '../types/brandData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faEye, faEdit, faSave, faBan, faTrash, faCancel } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';

const fetchData = async (page: number, perPage: number, params: any) => {
  try {
    const response = await TAfindAllBrands(page, perPage, params);
    if (response && Array.isArray(response.brands)) {
      const totalLength = response.brands.length;
      const totalPages = response.totalPages;
      const data = response.brands.map((item: any, index: any) => {
        return {
          id: totalLength - index,
          ...item,
        };
      });
      return { data, totalPages };
    }
  } catch (error: any) {
    throw new Error(error);
  }
};

const AllBrands = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageTitle('Range Search Table'));
  });
  const [userData, setUserData] = useState([] as AllBrandType[]);
  const [page, setPage] = useState(1);
  const PAGE_SIZES = [10, 20, 30, 50, 100];
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZES[2]);
  const [initialRecords, setInitialRecords] = useState(sortBy(userData, 'id'));
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({ columnAccessor: 'id', direction: 'asc' });
  const [error, setError] = useState<string | null>(null);
  // const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState('');
  const [brandNotes, setBrandNotes] = useState(['']);
  const [editMode, setEditMode] = useState<EditModeState>({});
  const [editStage, setEditStage] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState<DropdownState>({});
  const [params, setParams] = useState({
    min_created_at: null as Date | null,
    max_created_at: null as Date | null,
    brand: '',
    stage: [''],
    process: {
      meeting_planned: false,
      information_mail_sent: false,
      directed_to_agency_based_work: false,
      demo_campaign_created: false,
      balance_loaded: false,
      campaign_management_continues: false,
      campaign_completed: false,
      personal_account: false,
    },
  });
  const [brandMeetings, setBrandMeetings] = useState<Meetings>([
    {
      time: new Date(),
      description: '',
    },
  ]);

  type DropdownState = {
    [key: string]: boolean;
  };
  interface EditModeState {
    [key: string]: boolean;
  }

  useEffect(() => {
    setLoading(true);
    const loadBrands = async () => {
      try {
        const newParams = {
          min_created_at: params.min_created_at ? params.min_created_at.toISOString() : '',
          max_created_at: params.max_created_at ? params.max_created_at.toISOString() : '',
          brand: params.brand,
          stage: params.stage.join(';'),
          meeting_planned: params.process.meeting_planned.toString(),
          information_mail_sent: params.process.information_mail_sent.toString(),
          directed_to_agency_based_work: params.process.directed_to_agency_based_work.toString(),
          demo_campaign_created: params.process.demo_campaign_created.toString(),
          balance_loaded: params.process.balance_loaded.toString(),
          campaign_management_continues: params.process.campaign_management_continues.toString(),
          campaign_completed: params.process.campaign_completed.toString(),
          personal_account: params.process.personal_account.toString(),
        };
        const queryParams = new URLSearchParams(newParams as Record<string, string>).toString();
        const response = await fetchData(page, pageSize, queryParams);
        if (response !== undefined) {
          setInitialRecords(response.data);
          setTotalPages(response.totalPages);
          setLoading(false);
        } else {
          setError('No data found');
        }
      } catch (error) {
        setError('Error fetching data');
      }
    };
    loadBrands();
  }, [page, pageSize, params]);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  // useEffect(() => {
  //   const handleClick = () => {
  //     setIsDropdownOpen(false);
  //   };
  //   document.addEventListener('click', handleClick);
  //   return () => {
  //     document.removeEventListener('click', handleClick);
  //   };
  // }, []);

  const renderBrandId = (record: any, index: number) => {
    const itemsPerPage = page * pageSize;
    const recordIndex = itemsPerPage + index;
    const brandId = recordIndex - pageSize + 1;
    return <div>{brandId}</div>;
  };

  const handleUpdateNote = async (_id: string, brandNotes: string[]) => {
    try {
      const brand = await TAupdateBrandNote(_id, brandNotes);
      if (brand) {
        alert('Note updated successfully');
        setEditMode((prev) => ({ ...prev, [_id]: false }));
      }
    } catch (error) {
      console.error(error);
      alert('Failed to update the note');
    }
  };

  const handleDeleteNote = (_id: any, index: any) => {
    const newNotes = brandNotes.filter((note, noteIndex) => noteIndex !== index);
    handleUpdateNote(_id, newNotes);
  };

  const handleUpdateMeeting = async (_id: any, brandMeetings: any) => {
    try {
      const brand = await TAupdateBrandMeeting(_id, brandMeetings);
      if (brand) alert('Meeting updated successfully');
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteMeeting = (_id: any, index: any) => {
    if (brandMeetings !== undefined) {
      const newMeetings = brandMeetings.filter((meeting, meetingIndex) => meetingIndex !== index);
      handleUpdateMeeting(_id, newMeetings);
    }
  };

  // const handleInputChange = (_id: string, value: string) => {
  //   setNotes((prev) => ({ ...prev, [_id]: value }));
  // };

  const toggleEditMode = (_id: string, initialNote: string[] | string) => {
    setEditMode((prev) => ({ ...prev, [_id]: true }));
    const noteString = Array.isArray(initialNote) ? initialNote.join(', ') : initialNote;
    // setNotes((prev) => ({ ...prev, [_id]: noteString }));
  };

  const defaultStages = [
    'Arandı',
    'Ulaşılamadı',
    'Tekrar Arandı',
    'Ulaşılamadı, bilgilendirme mesajı iletildi',
    'Kişisel hesap, bilgilendirme verilmedi',
  ];

  const handleBrandStage = async (_id: any, stages: any[]) => {
    try {
      const brand = await TAupdateBrandStage(_id, stages);
      if (brand) alert('Stage updated successfully');
    } catch (error) {
      console.error(error);
    }
  };

  const handleMeetingPlanned = async (_id: any, status: any) => {
    try {
      const brand = await TAmeetingPlanned(_id, status);
      if (brand) alert('Meeting planned status updated successfully');
    } catch (error) {
      console.error(error);
    }
  };

  const handleInformationMailSent = async (_id: any, status: any) => {
    try {
      const brand = await TAinformationMailSent(_id, status);
      if (brand) alert('Information Mail Sent status updated successfully');
    } catch (error) {
      console.error(error);
    }
  };

  const handleDirectedToAgencyBasedWork = async (_id: any, status: any) => {
    try {
      const brand = await TAdirectedToAgencyBasedWork(_id, status);
      if (brand) alert('Directed to Agency Based Work status updated successfully');
    } catch (error) {
      console.error(error);
    }
  };

  const handleDemoCampaignCreated = async (_id: any, status: any) => {
    try {
      const brand = await TAdemoCampaignCreated(_id, status);
      if (brand) alert('Demo Campaign Created status updated successfully');
    } catch (error) {
      console.error(error);
    }
  };

  const handleBalanceLoaded = async (_id: any, status: any) => {
    try {
      const brand = await TAbalanceLoaded(_id, status);
      if (brand) alert('Balance Loaded status updated successfully');
    } catch (error) {
      console.error(error);
    }
  };

  const handleCampaignManagementContinues = async (_id: any, status: any) => {
    try {
      const brand = await TAcampaignManagementContinues(_id, status);
      if (brand) alert('Campaign Management Continues status updated successfully');
    } catch (error) {
      console.error(error);
    }
  };

  const handleCampaignCompleted = async (_id: any, status: any) => {
    try {
      const brand = await TAcampaignCompleted(_id, status);
      if (brand) alert('Campaign Completed status updated successfully');
    } catch (error) {
      console.error(error);
    }
  };

  const handlePersonalAccount = async (_id: any, status: any) => {
    try {
      const brand = await TApersonalAccount(_id, status);
      if (brand) alert('Personal Account status updated successfully');
    } catch (error) {
      console.error(error);
    }
  };

  const handleSpamBrand = (_id: any) => {
    TASpamBrand(_id, 'true');
  };

  const handleStartDateChange = (date: Date | null) => {
    setParams((prevParams: any) => ({
      ...prevParams,
      min_created_at: date,
    }));
    if (params.max_created_at && date && date > params.max_created_at) {
      setParams((prevParams: any) => ({
        ...prevParams,
        max_created_at: null,
      }));
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    setParams((prevParams: any) => ({
      ...prevParams,
      max_created_at: date,
    }));
  };

  const handleStageRadioChange = (stage: string) => {
    if (stage === '') {
      setParams({ ...params, stage: [''] });
    } else {
      let stages = [];
      if (params.stage.includes('')) {
        stages = [stage];
      } else {
        const index = params.stage.indexOf(stage);
        if (index !== -1) {
          stages = params.stage.filter((s) => s !== stage);
        } else {
          stages = [...params.stage, stage];
        }
      }
      setParams({ ...params, stage: stages });
    }
  };

  const isChecked = (stage: string) => {
    if (stage === '') {
      return params.stage.includes('');
    } else {
      return params.stage.includes(stage);
    }
  };

  const handleBrandProcessRadioChange = (process: string) => {
    switch (process) {
      case 'Toplantı planlandı':
        setParams({ ...params, process: { ...params.process, meeting_planned: !params.process.meeting_planned } });
        break;
      case 'Bilgilendirme maili iletildi':
        setParams({
          ...params,
          process: { ...params.process, information_mail_sent: !params.process.information_mail_sent },
        });
        break;
      case 'Ajans bazlı çalışmaya yönlendirildi':
        setParams({
          ...params,
          process: { ...params.process, directed_to_agency_based_work: !params.process.directed_to_agency_based_work },
        });
        break;
      case 'Demo kampanya oluşturdu':
        setParams({
          ...params,
          process: { ...params.process, demo_campaign_created: !params.process.demo_campaign_created },
        });
        break;
      case 'Bakiye yüklemesi yapıldı':
        setParams({ ...params, process: { ...params.process, balance_loaded: !params.process.balance_loaded } });
        break;
      case 'Kampanya yönetim süreci devam ediyor':
        setParams({
          ...params,
          process: { ...params.process, campaign_management_continues: !params.process.campaign_management_continues },
        });
        break;
      case 'Kampanya tamamlandı':
        setParams({
          ...params,
          process: { ...params.process, campaign_completed: !params.process.campaign_completed },
        });
        break;
      case 'Kişisel Hesap':
        setParams({
          ...params,
          process: { ...params.process, personal_account: !params.process.personal_account },
        });
        break;
      case '':
        setParams({
          ...params,
          process: {
            ...params.process,
            meeting_planned: false,
            information_mail_sent: false,
            directed_to_agency_based_work: false,
            demo_campaign_created: false,
            balance_loaded: false,
            campaign_management_continues: false,
            campaign_completed: false,
            personal_account: false,
          },
        });
        break;
      default:
        break;
    }
  };

  const isProcessChecked = (newProcess: string) => {
    switch (newProcess) {
      case 'Toplantı planlandı':
        return params.process.meeting_planned;
      case 'Bilgilendirme maili iletildi':
        return params.process.information_mail_sent;
      case 'Ajans bazlı çalışmaya yönlendirildi':
        return params.process.directed_to_agency_based_work;
      case 'Demo kampanya oluşturdu':
        return params.process.demo_campaign_created;
      case 'Bakiye yüklemesi yapıldı':
        return params.process.balance_loaded;
      case 'Kampanya yönetim süreci devam ediyor':
        return params.process.campaign_management_continues;
      case 'Kampanya tamamlandı':
        return params.process.campaign_completed;
      case 'Kişisel Hesap':
        return params.process.personal_account;
      case '':
        return (params.process as any)[newProcess];
      default:
        break;
    }
  };

  const handleDropdownClick = (id: string) => {
    setDropdownOpen((prev: any) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleOptionChange = (handler: any, _id: string, value: any) => {
    handler(_id, value);
  };

  return (
    <div className="panel">
      <div className="mb-4.5 flex md:items-center md:flex-row flex-col gap-5">
        {error && <div className="bg-red-200 text-red-800 border border-red-600 p-2 rounded">{error}</div>}
        <div className="flex items-center flex-col items-start mt-4">
          <div className="flex-col">
            <label className="mr-14 mb-4">Created Date Range</label>
          </div>
          <div className="mr-4" style={{ zIndex: 9999 }}>
            <DatePicker
              selected={params.min_created_at}
              onChange={handleStartDateChange}
              selectsStart
              startDate={params.min_created_at}
              dateFormat="dd/MM/yyyy"
              endDate={params.max_created_at}
              className="w-full p-2 border border-gray-200 rounded-md mb-4"
              placeholderText="Başlangıç Tarihi (gg/aa/yyyy)"
            />
          </div>
          <div className="mr-4" style={{ zIndex: 999 }}>
            <DatePicker
              selected={params.max_created_at}
              onChange={handleEndDateChange}
              selectsEnd
              startDate={params.min_created_at}
              dateFormat="dd/MM/yyyy"
              endDate={params.max_created_at}
              minDate={params.min_created_at}
              className="w-full p-2 border border-gray-200 rounded-md mb-4"
              placeholderText="Bitiş Tarihi (gg/aa/yyyy)"
            />
          </div>
        </div>
        <div className="md:flex md:flex-col mr-2 ml-2">
          <h2 className="text-sm font-bold mb-2">Stages</h2>
          {[
            'Arandı',
            'Ulaşılamadı',
            'Tekrar Arandı',
            'Ulaşılamadı, bilgilendirme mesajı iletildi',
            'Kişisel hesap, bilgilendirme verilmedi',
            '',
          ].map((label) => (
            <label key={label}>
              <input
                type="checkbox"
                value={label}
                checked={isChecked(label)}
                onChange={() => handleStageRadioChange(label)}
                className="form-checkbox text-pink-600 mr-2"
              />
              {label || 'Any'}
            </label>
          ))}
        </div>
        <div className="md:flex md:flex-col mr-2 ml-2">
          <h2 className="text-sm font-bold mb-2">Brand Process</h2>
          {[
            'Toplantı planlandı',
            'Bilgilendirme maili iletildi',
            'Ajans bazlı çalışmaya yönlendirildi',
            'Demo kampanya oluşturdu',
            'Bakiye yüklemesi yapıldı',
            'Kampanya yönetim süreci devam ediyor',
            'Kampanya tamamlandı',
            'Kişisel Hesap',
            '',
          ].map((label) => (
            <label key={label}>
              <input
                type="checkbox"
                value={label}
                checked={isProcessChecked(label)}
                onChange={() => handleBrandProcessRadioChange(label)}
                className="form-checkbox text-pink-600 mr-2"
              />
              {label || 'Any'}
            </label>
          ))}
        </div>
        <div className="ltr:ml-auto rtl:mr-auto flex">
          <input
            type="text"
            className="form-input w-auto mr-4"
            placeholder="Search Brand Name"
            value={params.brand}
            onChange={(e) => {
              const text = e.target.value;
              setParams((prevParams: any) => ({
                ...prevParams,
                brand: text,
              }));
            }}
          />
          {/* {isDropdownOpen && searchMatches.length > 0 && (
            <div className="absolute bg-white border border-gray-300 rounded mt-10 z-10">
              {searchMatches.slice(0, 4).map((match: AllBrandType) => (
                <div
                  className="p-2 border-b border-gray-300 hover:bg-gray-100"
                  key={match.id}
                  onClick={() => handleBrandNameSelect(match)}
                >
                  {match.brand_name}
                </div>
              ))}
            </div>
          )} */}
        </div>
      </div>
      <div className="datatables">
        {loading ? (
          <div className="flex items-center justify-center h-40">
            <div className="animate-spin rounded-full h-10 w-10 border-t-4 border-pink-600"></div>
          </div>
        ) : (
          <DataTable
            highlightOnHover
            className="whitespace-nowrap table-hover"
            records={initialRecords}
            columns={[
              {
                accessor: 'brand',
                title: 'Details',
                sortable: false,
                render: ({ _id }: any) => (
                  <a href={`/brands/find/${_id}`} target="_blank" rel="noopener noreferrer">
                    <div className="text-center items-center mr-4">
                      <FontAwesomeIcon icon={faEye} style={{ color: '#005eff' }} />
                    </div>
                  </a>
                ),
              },
              { accessor: 'id', title: 'Id', render: renderBrandId },
              {
                accessor: 'firstName',
                title: 'Name',
                render: ({ first_name, last_name }) => <div>{`${first_name} ${last_name}`}</div>,
              },
              {
                accessor: 'balance',
                title: 'Balance',
                render: ({ balance }: any) => (
                  <div>
                    {balance > 0 ? (
                      <div>
                        <p
                          style={{
                            color: '#009e1a',
                            display: 'inline-block',
                            marginRight: '5px',
                            fontWeight: 'bold',
                          }}
                        >
                          {balance}
                        </p>
                        <FontAwesomeIcon icon={faDollarSign} style={{ color: '#009e1a' }} />
                      </div>
                    ) : (
                      <div>{balance}</div>
                    )}
                  </div>
                ),
              },
              { accessor: 'brand_name', title: 'Brand Name' },
              { accessor: 'first_name', title: 'First Name' },
              { accessor: 'last_name', title: 'Last Name' },
              { accessor: 'email', title: 'Email' },
              { accessor: 'phone', title: 'Phone' },
              {
                accessor: 'created_at',
                title: 'Created At',
                render: ({ created_at }: any) => <div>{new Date(created_at).toLocaleDateString()}</div>,
              },
              {
                accessor: 'is_spam',
                title: 'Is Spam',
                render: ({ _id }: any) => (
                  <div>
                    <FontAwesomeIcon
                      icon={faBan}
                      style={{ color: 'green', cursor: 'pointer', marginLeft: '10px' }}
                      onClick={() => handleSpamBrand(_id)}
                    />
                  </div>
                ),
              },
              {
                accessor: 'notes',
                title: 'Notes',
                render: ({ _id, notes: initialNote }: { _id: string; notes: string[] }) => (
                  <div className="text-center items-center mr-4">
                    {editMode[_id] ? (
                      <div>
                        <ul>
                          {initialNote
                            .filter((note) => note !== '')
                            .map((note, index) => (
                              <li key={index} className="flex items-center">
                                <p className="text-gray-600 mt-2">{note}</p>
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  className="mt-2"
                                  style={{ color: '#005eff', marginLeft: '8px', cursor: 'pointer' }}
                                  onClick={() => handleDeleteNote(_id, index)}
                                />
                              </li>
                            ))}
                        </ul>
                        <input type="text" onChange={(e) => setNotes(e.target.value)} className="text-input mt-2" />
                        <FontAwesomeIcon
                          icon={faSave}
                          style={{ color: 'green', cursor: 'pointer', marginLeft: '10px' }}
                          onClick={() => handleUpdateNote(_id, [...initialNote, notes])}
                        />
                      </div>
                    ) : (
                      <>
                        <span>{initialNote}</span>
                        <FontAwesomeIcon
                          icon={faEdit}
                          style={{ color: '#005eff', cursor: 'pointer', marginLeft: '10px' }}
                          onClick={() => {
                            toggleEditMode(_id, initialNote);
                            setBrandNotes(initialNote);
                          }}
                        />
                      </>
                    )}
                  </div>
                ),
              },
              {
                accessor: 'meetings',
                title: 'Meetings',
                render: ({ _id, meetings: initialMeeting }: { _id: string; meetings: Meetings }) => (
                  <div className="text-center items-center mr-4">
                    <ul>
                      {initialMeeting &&
                        initialMeeting.map((meeting, index) => (
                          <li key={index} className="flex items-center">
                            <p className="text-gray-600 mt-2">{new Date(meeting.time).toLocaleString('tr-TR')}</p>
                            <p className="text-gray-600 mt-2 ml-4 font-bold">{meeting.description}</p>
                          </li>
                        ))}
                    </ul>
                  </div>
                ),
              },
              {
                accessor: 'stages',
                title: 'Stages',
                render: ({ _id, stages: initialStages }: { _id: string; stages: Stages }) => (
                  <div className="text-center items-center mr-4">
                    {initialStages && initialStages.length > 0 ? (
                      <React.Fragment>
                        {editStage ? (
                          <div>
                            <select
                              value={initialStages[0].stage}
                              onChange={(e) =>
                                handleBrandStage(_id, [
                                  { stage: e.target.value, stage_time: new Date() },
                                  ...initialStages,
                                ])
                              }
                              className="mt-2 p-1 border rounded"
                            >
                              {defaultStages.map((stageOption, index) => (
                                <option key={index} value={stageOption}>
                                  {stageOption}
                                </option>
                              ))}
                            </select>
                            <FontAwesomeIcon
                              icon={faCancel}
                              style={{ color: '#005eff', cursor: 'pointer', marginLeft: '10px' }}
                              onClick={() => setEditStage(false)}
                            />
                          </div>
                        ) : (
                          <ul>
                            <li className="flex-col" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                              <div className="flex">
                                <p className="text-black-600 font-bold">{initialStages[0].stage}</p>
                                <FontAwesomeIcon
                                  icon={faEdit}
                                  style={{ color: '#005eff', cursor: 'pointer', marginLeft: '10px' }}
                                  onClick={() => setEditStage(true)}
                                />
                              </div>
                              <p className="text-gray-600 mr-40">
                                {new Date(initialStages[0].stage_time).toLocaleString('tr-TR')}
                              </p>
                            </li>
                          </ul>
                        )}
                      </React.Fragment>
                    ) : (
                      <div>
                        <select
                          value={'Select Stage'}
                          onChange={(e) => handleBrandStage(_id, [{ stage: e.target.value, stage_time: new Date() }])}
                          className="mt-2 p-1 border rounded"
                        >
                          <option disabled>{'Select Stage'}</option>
                          {defaultStages.map((stageOption, index) => (
                            <option key={index} value={stageOption}>
                              {stageOption}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>
                ),
              },
              {
                accessor: 'actions',
                title: 'Brand Actions',
                render: ({
                  _id,
                  meeting_planned,
                  information_mail_sent,
                  directed_to_agency_based_work,
                  demo_campaign_created,
                  balance_loaded,
                  campaign_management_continues,
                  campaign_completed,
                  personal_account,
                }) => (
                  <div>
                    <div
                      onClick={() => handleDropdownClick(_id)}
                      className="dropdown-trigger cursor-pointer font-bold text-blue-600"
                    >
                      Open/Close Actions
                    </div>
                    {dropdownOpen[_id] && (
                      <div className="dropdown-menu mt-2">
                        <label>
                          <input
                            className="mr-2"
                            type="checkbox"
                            checked={meeting_planned}
                            onChange={(e) => handleOptionChange(handleMeetingPlanned, _id, e.target.checked)}
                          />
                          Toplantı Planlandı
                        </label>
                        <label>
                          <input
                            className="mr-2"
                            type="checkbox"
                            checked={information_mail_sent}
                            onChange={(e) => handleOptionChange(handleInformationMailSent, _id, e.target.checked)}
                          />
                          Bilgilendirme Maili İletildi
                        </label>
                        <label>
                          <input
                            className="mr-2"
                            type="checkbox"
                            checked={directed_to_agency_based_work}
                            onChange={(e) => handleOptionChange(handleDirectedToAgencyBasedWork, _id, e.target.checked)}
                          />
                          Ajans Bazlı Çalışmaya Yönlendirildi
                        </label>
                        <label>
                          <input
                            className="mr-2"
                            type="checkbox"
                            checked={demo_campaign_created}
                            onChange={(e) => handleOptionChange(handleDemoCampaignCreated, _id, e.target.checked)}
                          />
                          Demo Kampanya Oluşturdu
                        </label>
                        <label>
                          <input
                            className="mr-2"
                            type="checkbox"
                            checked={balance_loaded}
                            onChange={(e) => handleOptionChange(handleBalanceLoaded, _id, e.target.checked)}
                          />
                          Bakiye Yüklemesi Yapıldı
                        </label>
                        <label>
                          <input
                            className="mr-2"
                            type="checkbox"
                            checked={campaign_management_continues}
                            onChange={(e) =>
                              handleOptionChange(handleCampaignManagementContinues, _id, e.target.checked)
                            }
                          />
                          Kampanya Yönetim Süreci Devam Ediyor
                        </label>
                        <label>
                          <input
                            className="mr-2"
                            type="checkbox"
                            checked={campaign_completed}
                            onChange={(e) => handleOptionChange(handleCampaignCompleted, _id, e.target.checked)}
                          />
                          Kampanya Tamamlandı
                        </label>
                        <label>
                          <input
                            className="mr-2"
                            type="checkbox"
                            checked={personal_account}
                            onChange={(e) => handleOptionChange(handlePersonalAccount, _id, e.target.checked)}
                          />
                          Kişisel Hesap
                        </label>
                      </div>
                    )}
                  </div>
                ),
              },
            ]}
            totalRecords={totalPages * pageSize}
            recordsPerPage={pageSize}
            page={page}
            onPageChange={(p) => setPage(p)}
            recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={setPageSize}
            sortStatus={sortStatus}
            onSortStatusChange={setSortStatus}
            minHeight={200}
            paginationText={({ from, to, totalRecords }) => `Showing  ${from} to ${to} of ${totalRecords} entries`}
          />
        )}
      </div>
    </div>
  );
};

export default AllBrands;
