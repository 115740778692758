import apiClient from './axiosInstance';

export const TAgetPopularHashtagSearchPost = async () => {
  try {
    const response = await apiClient.get(`/insta/insta-popular-hashtag-post/`, {});
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Find post failed');
    }
  } catch (error) {
    throw error;
  }
};

export const TAgetHashtagPostsLastThreeMonths = async (page: number, perPage: number, description: string) => {
  try {
    const response = await apiClient.get(
      `/insta/insta-hashtag-posts-three-months?page=${page}&perPage=${perPage}&description=${description}`,
      {},
    );
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Find post failed');
    }
  } catch (error) {
    throw error;
  }
};

export const TAgetMostContentUsernames = async (perPage: any, page: number) => {
  try {
    const response = await apiClient.get(`/insta/insta-most-content-usernames?perPage=${perPage}&page=${page}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Update failed');
    }
  } catch (error) {
    throw error;
  }
};

export const TAgetTeamfluencerData = async () => {
  try {
    const response = await apiClient.get(`/insta/insta-teamfluencer`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Update failed');
    }
  } catch (error) {
    throw error;
  }
};
