import apiClient from './axiosInstance';

export const TAfindBrand = async (data: any) => {
  try {
    let query = '';
    if (data.email) {
      query = `?email=${data.email}`;
    } else if (data.phone) {
      query = `?phone=${data.phone}`;
    } else if (data.brandname) {
      query = `?brand_name=${data.brandname}`;
    }

    const response = await apiClient.get(`/admin/brand/get${query}`, {});

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

export const TAfindBrandName = async (data: any) => {
  try {
    const response = await apiClient.get(`/admin/brand/brandname?brand_name=${data}`, {});

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const TAfindAllBrands = async (page: number, perPage: number, params: any) => {
  try {
    const response = await apiClient.get(`/admin/brand/getall?page=${page}&perPage=${perPage}&${params}`, {});

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Brands not found');
    }
  } catch (error) {
    throw error;
  }
};

export const TAfindBrandById = async (id: any) => {
  try {
    const response = await apiClient.get(`/admin/brand/get/${id}`, {});
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Find User failed');
    }
  } catch (error) {
    throw error;
  }
};

export const TAupdateBrand = async (id: any, data: any) => {
  try {
    const response = await apiClient.put(`/admin/brand/update/${id}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
      console.log('response.data', response.data.brand_logo);
      return response.data;
    } else {
      throw new Error('Update failed');
    }
  } catch (error) {
    throw error;
  }
};

export const TAupdateBrandNote = async (id: string, notes: string[]) => {
  try {
    const response = await apiClient.put(
      `/admin/brand/updatenote/${id}`,
      { notes },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Update failed');
    }
  } catch (error) {
    throw error;
  }
};

export const TAdeleteNote = async (id: any) => {
  try {
    const response = await apiClient.delete(`admin/brand/deletenote/${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Delete failed');
    }
  } catch (error) {
    throw error;
  }
};

export const TAaddBalance = async (id: any, balance: number) => {
  try {
    const response = await apiClient.put(`/admin/brand/addbalance/${id}/${balance}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Update failed');
    }
  } catch (error) {
    throw error;
  }
};

export const TASpamBrand = async (id: any, is_spam: any) => {
  try {
    const response = await apiClient.put(`/admin/brand/setspambrand/${id}/${is_spam}`, {});
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Find User failed');
    }
  } catch (error) {
    throw error;
  }
};

export const TAupdateBrandLogo = async (id: any, data: any) => {
  try {
    const response = await apiClient.put(`/admin/brand/updatebrandlogo/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent></calculated>',
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const TAupdateBrandMeeting = async (id: string, meeting: any) => {
  try {
    const response = await apiClient.put(
      `/admin/brand/updatemeeting/${id}`,
      { meeting },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Update failed');
    }
  } catch (error) {
    throw error;
  }
};

export const TAupdateBrandStage = async (id: string, stage: any) => {
  try {
    const response = await apiClient.put(
      `/admin/brand/stage/${id}`,
      { stage },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Update failed');
    }
  } catch (error) {
    throw error;
  }
};

export const TAmeetingPlanned = async (id: string, status: any) => {
  try {
    const response = await apiClient.post(
      `/admin/brand/meeting-planned/${id}`,
      { status },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Update failed');
    }
  } catch (error) {
    throw error;
  }
};

export const TAinformationMailSent = async (id: string, status: any) => {
  try {
    const response = await apiClient.post(
      `/admin/brand/information-mail-sent/${id}`,
      { status },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Update failed');
    }
  } catch (error) {
    throw error;
  }
};

export const TAdirectedToAgencyBasedWork = async (id: string, status: any) => {
  try {
    const response = await apiClient.post(
      `/admin/brand/directed-to-agency-based-work/${id}`,
      { status },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Update failed');
    }
  } catch (error) {
    throw error;
  }
};

export const TAdemoCampaignCreated = async (id: string, status: any) => {
  try {
    const response = await apiClient.post(
      `/admin/brand/demo-campaign-created/${id}`,
      { status },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Update failed');
    }
  } catch (error) {
    throw error;
  }
};

export const TAbalanceLoaded = async (id: string, status: any) => {
  try {
    const response = await apiClient.post(
      `/admin/brand/balance-loaded/${id}`,
      { status },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Update failed');
    }
  } catch (error) {
    throw error;
  }
};

export const TAcampaignManagementContinues = async (id: string, status: any) => {
  try {
    const response = await apiClient.post(
      `/admin/brand/campaign-management-continues/${id}`,
      { status },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Update failed');
    }
  } catch (error) {
    throw error;
  }
};

export const TAcampaignCompleted = async (id: string, status: any) => {
  try {
    const response = await apiClient.post(
      `/admin/brand/campaign-completed/${id}`,
      { status },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Update failed');
    }
  } catch (error) {
    throw error;
  }
};

export const TApersonalAccount = async (id: string, status: any) => {
  try {
    const response = await apiClient.post(
      `/admin/brand/personal-account/${id}`,
      { status },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Update failed');
    }
  } catch (error) {
    throw error;
  }
};
